import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// socket
import { io } from "socket.io-client";
export const socket = io('https://server.maxinify.com');

// router config
import VueRouter from 'vue-router'
import { Routes } from './Routes'
Vue.use(VueRouter)
const router = new VueRouter({
  routes: Routes,
  mode: 'history',
  linkExactActiveClass: "active",
})

// axios config
import axios from "axios";

// axios.defaults.baseURL = process.env.VUE_APP_API
// Vue.prototype.$hostname = `${process.env.VUE_APP_API_URL}/`

axios.defaults.baseURL = 'https://server.maxinify.com';
Vue.prototype.$hostname = 'https://server.maxinify.com/'
axios.defaults.headers.common['token'] = localStorage.getItem("token") || ""

// vuex
import { store } from './Store.js'


// apexchart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)



new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

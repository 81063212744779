import axios from "axios";

export default {
    data: () => ({
        status: '',
        amount: '',
    }),

    mounted() {
        window.scrollTo(0, 0);
        this.get_result()
    },

    methods: {
        async get_result() {
            this.$store.commit("progress_status", true);
            await axios({
                method: "GET",
                url: "/api/transaction/verify",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                },
                params: {
                    transactionId: this.$route.query.token
                }
            })
                .then((res) => {
                    console.log(res.data.data);
                    this.status = "success"
                    this.amount = res.data.data.transaction.amount
                })
                .catch((err) => {
                    this.status = "failed"

                    console.log(err);
                    // this.$store.commit("snackbar", {
                    //     color: "red",
                    //     text: err.response.data.errors,
                    // });
                })
                .finally(() => {
                    this.$store.commit("progress_status", false);
                });
        },

    },
};
import axios from "axios";


export default {


    data: () => ({
        dialog: false,
        amount: '',
        withdraws: [],
    }),

    mounted() {
        window.scrollTo(0, 0);

    },

    methods: {
        async pay() {
            this.$store.commit("progress_status", true);
            await axios({
                method: "POST",
                url: "/api/transaction",
                headers: {
                    Authorization: "Bearer" + " " + localStorage.getItem("token"),
                },
                data: {
                    amount: this.amount
                }
            })
                .then((res) => {
                    console.log(res.data.data.redirectLink);
                    this.dialog = false
                    this.amount = ''
                    window.open(res.data.data.redirectLink.href)
                })
                .catch((err) => {
                    console.log(err);
                    this.$store.commit("snackbar", {
                        color: "red",
                        text: err.response.data.errors,
                    });
                    this.get_user();
                })
                .finally(() => {
                    this.$store.commit("progress_status", false);
                });
        },

        cut(num) {
            let x = +num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            return x.toFixed(2)
        },

        fixed(num) {
            this.amount = (+num).toFixed(2)

        },
    }
};